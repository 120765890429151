<template>
    <card>
        <div class="mt-2">
            <form class="" role="form">
                <div class="row">
                    <div v-if="type == 'company' && !specialCourseFlag" class="col-6">
                        <input v-model="card.paymentType" name="paymenttype" type="radio" value="monthly"/>
                        Monthly
                    </div>
                    <div v-if="type == 'company' && !specialCourseFlag" class="col-6">
                        <input v-model="card.paymentType" name="paymenttype" type="radio" value="yearly"/>
                        Yearly
                    </div>
                    <div class="col-12 mb-2">
                        Amount Payable:
                        <span v-if="card.paymentType == 'monthly' && monthlyAmount">{{ formatPrice(monthlyAmount) }}</span>
                        <span v-if="card.paymentType == 'yearly' && yearlyAmount">{{ formatPrice(yearlyAmount) }}</span>
                    </div>
                </div>
                <span>
                    <stripe-element-card ref="elementRef" :hidePostalCode="postalCode" :pk="publicKey" @token="tokenCreated"/>
                </span>
                <base-input v-model="address" label="Billing Address *" name="Address" placeholder="Address" rules="required" type="text"></base-input>
                <div class="row">
                    <div class="col-md-5 col-12">
                        <base-input v-model="city" label="City *" name="City" placeholder="City" rules="required" type="text"></base-input>
                    </div>

                    <div class="col-md-3 col-12">
                        <base-input v-model="state" label="State *" name="State" placeholder="State" rules="required" type="text"></base-input>
                    </div>

                    <div class="col-md-4 col-12">
                        <base-input v-model="zip" label="Zip Code *" name="Zip code" placeholder="Zip" rules="required" type="number"></base-input>
                    </div>
                </div>
                <base-button block class="custom-btn" @click.prevent="paymentClicked" :disabled="enablePaymentButton">Pay & Create Account</base-button>
            </form>
        </div>
        <div class="row mt-4 justify-content-between align-items-left">
            <div class="col-6 text-left">
                <img alt="Image placeholder" src="img/icons/cards/pci-dss-logo.png" width="100px"/>
            </div>
            <div class="col-6 text-right">
                <img alt="Image placeholder" src="img/icons/cards/credit-card.jpg" width="150px"/>
            </div>
        </div>
    </card>
</template>
<script>
import {StripeElementCard} from "@vue-stripe/vue-stripe";

export default {
    name: "master-card",
    //props: ["monthlyAmount",],
    components: {
        StripeElementCard,
    },
    props: {
        type: String,
        address: String,
        state: String,
        city: String,
        zip: String,
        monthlyAmount: Number,
        yearlyAmount: Number,
    },
    data() {
        return {
            card: {
                cardNumber: "",
                expire: "",
                paymentType: "monthly",
                address: this.address,
                state: this.state,
                city: this.city,
                zip: this.zip,
                token: null,
            },
            publicKey: '',
            postalCode: true,
            enablePaymentButton: false,
        };
    },
    created() {
        this.publicKey = process.env.VUE_APP_STRIPE_NMRA_PUBLIC_KEY
        if (this.type == "company") {
            if (this.specialCourseFlag) {
                this.card.paymentType = "yearly";
            } else {
                this.card.paymentType = "monthly";
            }
        }
    },
    methods: {
        formatPrice(value) {
            return "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },
        tokenCreated(token) {
            this.card.token = token;
            this.card.zip = this.zip;
            this.card.address = this.address;
            this.card.state = this.state;
            this.card.city = this.city;
            this.$emit("payClicked", this.card);
        },
        paymentClicked() {
            this.enablePaymentButton = true;
            this.$refs.elementRef.submit();
        },
        errorHandling: function(errors) {
            this.enablePaymentButton = false;
        }
    },
};
</script>
<style scoped>
.bg-gradient-primary {
    background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}
@media only screen and (max-width: 420px){
    div[onlysexualcourse = "0"] div:has(img[alt="Image placeholder"]) > img[alt="Image placeholder"] {
    width: 100px;
}
}
</style>
